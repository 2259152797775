<template>
  <v-form>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการสินค้า</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มสินค้า</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersproduct"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <!-- <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="UpdateProduct(item)" class="mx-2">
                  mdi-pencil
                </v-icon>
              </v-row>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      headersproduct: [
        { text: "ลำดับ", value: "count", align: "center", width: "100" },
        { text: "รหัสสินค้า", value: "itemNo", align: "center", width: "100" },
        {
          text: "ชื่อสินค้า",
          value: "description",
          align: "center",
          width: "100",
        },
        {
          text: "รายละเอียดสินค้า",
          value: "description2",
          align: "center",
          width: "150",
        },
        {
          text: "รายละเอียดสินค้า เพื่อการโฆษณา",
          value: "adsDescription",
          align: "center",
          width: "300",
        },
        {
          text: "หน่วย",
          value: "unitOfMeasure",
          align: "center",
          width: "100",
        },
        { text: "ราคาป้าย", value: "priceTag", align: "center", width: "100" },
        {
          text: "ราคาขายรวม VAT",
          value: "priceIncludingVAT",
          align: "center",
          width: "100",
        },
        {
          text: "สถานะสินค้า",
          value: "blocked",
          align: "center",
          width: "100",
        },
        {
          text: "ประเภทสินค้า",
          value: "itemCategory",
          align: "center",
          width: "100",
        },
        {
          text: "ค่าขนส่ง",
          value: "deliveryFee",
          align: "center",
          width: "100",
        },
        // { text: "ตัวเลือก", value: "action", align: "center", width: "120" },
      ],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      // adsDescription: (...)
      // blocked: (...)
      // count: 1
      // createdAt: (...)
      // deliveryFee: (...)
      // description: (...)
      // description2: (...)
      // id: (...)
      // isMap: (...)
      // itemCategory: (...)
      // itemNo: (...)
      // priceIncludingVAT: (...)
      // priceTag: (...)
      // unitOfMeasure: (...)
      // updatedAt: (...)
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.dropdown();
    this.getAllProduct();
    for (let i in this.items) {
      this.items[i].count = parseInt(i) + 1;
      //console.log(this.list);
    }
  },

  methods: {
    async dropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/getAllProductERP`
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    goToCreate() {
      this.$router.push("createproduct");
    },
    viewProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("viewProduct");
    },
    UpdateProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("EditProduct");
    },
    async DeleteProduct(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/products/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllProduct();
        }
      });
    },
  },
};
</script>